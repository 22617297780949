import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import { Storage } from '@ionic/storage';
import TabsPage from '../views/TabsPage.vue';

const store = new Storage()

store.create();

const redirect = await store.get('redirect') !== null ? true : false

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: redirect ? '/tabs/home' : '/swiper'
  },
  {
    path: '/login',
    component: () => import('@/views/LoginPage.vue')
  },
  {
    path: '/register',
    component: () => import('@/views/RegisterPage.vue')
  },
  {
    path: '/swiper',
    component: () => import('@/views/SwiperPage.vue')
  },
  {
    path: '/swiper-dictionary',
    component: () => import('@/views/SwiperDictionaryPage.vue')
  },
  {
    path: '/swiper-collaborator',
    component: () => import('@/views/SwiperCollaboratorPage.vue')
  },
  {
    path: '/satisfaction-survey',
    component: () => import('@/views/SatisfactionSurveyPage.vue')
  },
  {
    path: '/tabs/',
    component: TabsPage,
    children: [
      {
        path: '',
        redirect: '/tabs/home'
      },
      {
        path: 'home',
        component: () => import('@/views/HomePage.vue')
      },
      
      {
        path: 'catalog',
        component: () => import('@/views/CatalogPage.vue')
      },
      {
        path: 'catalog/:id',
        component: () => import('@/views/CatalogShowPage.vue')
      },
      {
        path: 'dictionary',
        component: () => import('@/views/DictionaryPage.vue')
      },
      {
        path: 'dictionary/:id',
        component: () => import('@/views/DictionaryShowPage.vue')
      },
      {
        path: 'about',
        component: () => import('@/views/AboutPage.vue')
      },
      {
        path: 'collaborator',
        component: () => import('@/views/CollaboratorPage.vue')
      },
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
